#authortitle {
  text-align: center;
}

#authordetailstable {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  table-layout: fixed !important;
  word-wrap: break-word;
  width: 60%;
}

#authordetailstable td:nth-child(even) {
  width: auto !important;
  padding: 5px;
  text-align: left;
}

#authordetailstable td:nth-child(odd) {
  width: auto !important;
  padding: 5px;
  text-align: center;
}
