#storiestitle {
  text-align: center;
}

#storiestable {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  table-layout: fixed !important;
  word-wrap: break-word;
  width: 95%;
}

#storiestable td {
  width: auto !important;
  padding: 5px;
}

.ui.card {
  width: 100% !important;
}

.ui.card>.content {
  font-size: 2.2vw !important;
}
