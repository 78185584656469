#readtypeatable {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  table-layout: auto !important;
  word-wrap: break-word;
  width: 80%;
}

#readtypeatable td {
  width: auto !important;
  padding: 5px;
}
