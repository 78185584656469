#submittitle {
  text-align: center;
}

#submittable {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  table-layout: fixed !important;
  word-wrap: break-word;
  width: 90%;
}

#submittable td {
  width: 70% !important;
  padding: 5px;
  text-align: left;
}

#submittable td:nth-child(odd) {
  width: 30% !important;
  padding: 5px;
  text-align: right;
}

.ui.input>input {
  width: 55vw;
}

.ui.form textarea {
  width: 55vw;
}

.ui.selection.active.dropdown:hover {
  border-color: #000;
}

.ui.selection.active.dropdown:hover .menu {
  border-color: #000;
}

.ui.selection.dropdown:focus {
  border-color: #000;
}

.ui.selection.dropdown:focus .menu {
  border-color: #000;
}

.ui.primary.button, .ui.primary.buttons .button {
  background-color: #000;
}

.ui.primary.button:active, .ui.primary.buttons .button:active {
  background-color: #000;
}

.ui.primary.button:hover, .ui.primary.buttons .button:hover {
  background-color: #000;
}
